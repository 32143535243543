

/* ✅ 父容器：强制所有页面元素居中 */
.container, .main-wrapper, .center-container, .bio-with-photo, .edu-wrapper, .github-heatmap {
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  padding: 0 30px;
  box-sizing: border-box;
}

/* ✅ 所有横向内容组：flex-wrap & 居中对齐 */
.bio-with-photo,
.edu-cards,
.skills,
.stage-track,
.edu-wrapper,
.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}

/* ✅ 图像和照片：百分比宽度适配小屏 */
.bio-photo img, .profile-image img {
  width: 100%;
  max-width: 250px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
}

/* ✅ 修复 GitHub 贡献图表错位 */
.github-heatmap {
  margin: 40px auto;
  justify-content: center;
  width: 100%;
}

/* ✅ 时间线卡片适配移动端 */
.edu-card {
  width: 100%;
  max-width: 350px;
  margin: 10px auto;
}

/* ✅ 阶段进程盒子对齐 */
.stage-box {
  min-width: 80px;
  text-align: center;
}

/* ✅ 全局 img */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}
body {
  padding: 0;
  margin: 0;
}



body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: var(--bg-color);
  color: var(--text-color);
  line-height: 1.6;
  box-sizing: border-box;
}

@media screen and (max-width: 480px) {
  .body {
    margin-left:100px;
  }

  .skills, .edu-cards,  {
    flex-direction: column;
    align-items: center;
  }
.projects-grid {
    width:300px;
    margin: 0 auto;
  }
  .project-card,
   .edu-card {
    width: 80%;
    max-width: 95%;
  }
}

/* 根布局修正，保证 app 居中、响应式 */
body,
#root {
  width: 100%;
  display: flex;
  justify-content: center;
}


/* 🍭 Swiper 调整 */
.project-swiper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}
#root {
  width: 100%;
  max-width: 100vw;      /* 限制最大宽度不能超出屏幕 */
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;   /* 居中内容 */
}

.container {
  margin-top: 150px;;
}
