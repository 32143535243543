

/* ======================= 导航栏样式 ================= */
/* 导航栏样式 */
nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 70px;
  background: rgb(244, 244, 244,0.8); /* 背景色 */
  padding: 0px 10px;
  display: flex; /* Flexbox 布局 */
  justify-content: space-between; /* 左右对齐 */
  align-items: center; /* 垂直居中 */
  z-index: 1000;
}

body.dark-mode nav {
  background: rgb(18,18,18,0.8); /* 黑色背景 */
}

/* 左侧 Logo 和按钮 */
.nav-left {
  display: flex;
  align-items: center; /* 垂直居中 */
}

.logo {
  width: 150px; /* Logo 大小 */
  height: 150px;
}

.theme-toggle {
  background: none; /* 无背景 */
  border: none; /* 无边框 */
  color: #fff; /* 按钮颜色 */
  font-size: 2rem; /* 字体大小 */
  cursor: pointer; /* 鼠标样式 */
  transition: transform 0.3s ease; /* 动画效果 */
}

.theme-toggle:hover {
  transform: rotate(30deg); /* 悬停旋转 */
}

/* 右侧导航链接 */
.nav-links {
  display: flex; /* 横向排列 */
  list-style: none; /* 去掉默认列表样式 */
  gap: 20px; /* 每个导航链接的间距 */
  margin-right: 20px; /* 右侧导航链接距离右侧边距 */
}

.nav-links li a {
  color: rgb(78, 76, 76); /* 文字颜色 */
  padding: 10px 20px; /* 内边距 */
  text-decoration: none; /* 去掉下划线 */
  /*border: 2.5px solid rgb(78, 76, 76); /* 初始透明边框 */
  border-radius: 30px; /* 圆角 */
  background-color: transparent; /* 默认透明背景 */
  font-weight: bold; /* 字体加粗 */
  transition: background-color 0.3s ease, color 0.3s ease; /* 平滑过渡效果 */
  box-shadow: 0 0 8px 2px silver, 0 0 12px 4px white; /* 添加发光阴影 */
}
body.dark-mode .nav-links li a {
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 0 8px 2px silver, 0 0 12px 4px gray; /* 夜间的发光效果 */
}
.nav-links li a:hover {
  background-color: rgb(78, 76, 76); /* 悬停时背景变黑 */
  color: #fff; /* 悬停时文字变白 */
  transform: scale(1.05); /* 悬停时稍微放大按钮 */
}
body.dark-mode .nav-links li a:hover {
  background-color: #ffffff; /* 悬停时背景变浅 */
  color: #000000; /* 悬停时文字变深 */
}



.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  position: fiexd;
  top: 0;
  z-index: 10000;
  transition: background-color 0.3s;
}

/* 白天模式 */
.navbar.light {
  background-color: rgba(255, 255, 255, 0.8);
  color: #000;
  backdrop-filter: blur(10px);
}

/* 黑夜模式 */
.navbar.dark {
  background-color: rgba(17, 17, 17, 0.9);
  color: #fff;
  backdrop-filter: blur(10px);
}

.logo {
  height: 40px;
}

.theme-toggle {
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  margin-right: 1rem;
}

.hamburger-menu {
  background: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
}

.nav-links {
  display: flex;
  gap: 1rem;
  list-style: none;
  transition: all 0.3s ease;
 
  padding-right: 50px;
}

.nav-links li a {
  color: inherit;
  text-decoration: none;
  font-weight: bold;
}











/* 移动端 */
@media (max-width: 768px) {
  .nav-right {
    position: relative; /* ⚠️必须有，让菜单以它为参照 */
  }

  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 40px; /* 🧡让它从汉堡按钮“下面”出来 */
    right: 0;
    background-color: rgba(255, 255, 255, 0.95);
    padding: 1rem;
    border-radius: 12px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
    z-index: 99;
    width: max-content;
    transform: translateX(0);
  }

  .hamburger-menu {
    display: block;
    z-index: 100; /* 🧡确保在最上层 */
  }

  .nav-links.active {
    display: flex;
  }


  .nav-links li {
    margin: 0.5rem 0;
  }

  .hamburger-menu {
    display: block;
  }

  .nav-links li a {
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 2rem;
    background: rgba(0, 0, 0, 0.05);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    transition: background 0.2s;
  }

  .navbar.dark .nav-links li a {
    background: rgba(255, 255, 255, 0.05);
    color: #fff;
  }

  .nav-links li a:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 480px) {
.navbar {
  
  padding: 1rem;
}
.logo {
  height: 30px;
}
.theme-toggle {
  font-size: 1.2rem;
  margin-left: 50px;
}
.hamburger-menu {
  font-size: 1.5rem;
  margin-right: 20px;
  padding-right:50px;
}
.nav-links li a {
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
}
}


/* 汉堡菜单默认样式 */
.hamburger-menu {
  display: none; /* 默认隐藏，仅移动端显示 */
  background: none;
  border: none;
  font-size: 1.6rem;
  cursor: pointer;
  z-index: 1001;
  color: #333;
}

body.dark-mode .hamburger-menu {
  color: #fff;
  background-color: transparent;
}

/* 移动设备上显示汉堡菜单 */
@media screen and (max-width: 768px) {
.hamburger-menu {
  background: #fff;
    display: block;
    margin-right: 40px;
   
}
  .nav-links {
    display: none; /* 默认隐藏导航链接 */
    flex-direction: column;
    gap: 30px;
    
    position: absolute;
    top: 70px; /* 确保在导航栏下方显示 */
    right: 0px; /* 靠右显示 */
    padding: 20px;
    border-radius: 0px;
    border-left: 1px;
    border-left: #000;
    z-index: 1000;
    position:absolute;
  }
  body.dark-mode .nav-links {
    background: rgba(18, 18, 18, 0.95); /* 夜间模式背景 */
  }

  .nav-links.active {
    display: flex; /* 激活时显示链接 */
    margin-top: -40px;
    margin-right:40px;
  }
}

.logo {
  width: 150px; /* Logo 大小 */
  height: 150px;
}