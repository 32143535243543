/* ===== Scroll to Top (Modern Round Button) ===== */
.scroll-top {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background-color: var(--scroll-bg, #f0f0f0);
  color: var(--scroll-icon, #333);
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.scroll-top.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-top:hover {
  transform: scale(1.2);
}

/* Dark Mode */
body.dark-mode .scroll-top {
  --scroll-bg: #222;
  --scroll-icon: #eee;
}

/* Mobile */
@media screen and (max-width: 480px) {
  .scroll-top {
    bottom: 20px;
    left: 15px;
    width: 36px;
    height: 36px;
    font-size: 1rem;
  }
}
.scroll-top {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
  width: 44px;
  height: 44px;
  border: none;
  border-radius: 50%;
  background-color: var(--scroll-bg, #f0f0f0);
  color: var(--scroll-icon, #333);
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 10px rgba(0,0,0,0.1);
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);  /* 👈 初始向下偏移 */
  transition: opacity 0.4s ease, transform 0.4s ease;
  cursor: pointer;
}

.scroll-top.visible {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);  /* 👈 出现时轻轻上升 */
}
