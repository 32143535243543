


/* ====== 联系部分 ====== */
/* Contact Section */
#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

h2 {
  margin-bottom: 20px;
}

/* 模拟浏览器窗口 */
.contact-browser {
  width: 85%;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

body.dark-mode .contact-browser {
  background-color: #2a2a2a;
  box-shadow: 0 4px 8px rgba(255, 0.2);
}

/* 浏览器顶部 */
.browser-header {
  height: 30px;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-radius: 10px 10px 0 0;
}

body.dark-mode .browser-header {
  background-color: #333;
  color: #ffffff;
}

/* 浏览器按钮 */
.browser-buttons {
  display: flex;
  gap: 5px;
}

.browser-buttons span {
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.browser-buttons .red {
  background-color: #ff5f57;
}

.browser-buttons .yellow {
  background-color: #ffbd2e;
}

.browser-buttons .green {
  background-color: #28c840;
}

/* 浏览器内容区域 */
.browser-content {
  padding: 50px;
}

/* Contact 表单 */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: calc(100% - 40px); /* 确保表单左右有适当的内边距 */
  max-width: 450px; /* 限制表单的最大宽度 */
  margin: 0 auto; /* 使表单居中 */

}

.contact-form label {
  font-weight: bold;
  font-size: 0.9rem;
  color: #333;
}

body.dark-mode .contact-form label {
  color: #ffffff; /* 白色文字 */
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 0.9rem;
}

.contact-form button {
  padding: 15px 0px;
  border: none;
  width: 30%;
  margin:0 auto;
  background-color: #4f4c4c;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #010204;
}

body.dark-mode .contact-form button {
  background-color: #ffffff;
  color: #000000; /* 黑色文字 */
}

.contact-form button:hover {
  background-color: #747474;
}



.success-message {
  color: #28a745;
  margin-top: 1rem;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
/* style.css 新增部分 */
.success-message {
  color: #28a745;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.error-message {
  color: #dc3545;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

button.loading {
  opacity: 0.7;
  cursor: not-allowed;
}

.fa-spinner {
  animation: fa-spin 1s infinite linear;
}

@keyframes fa-spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
