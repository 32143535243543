/* theme.css */
/* 🎨 Light / Dark 模式主题变量 */

body.light {
  --bg-color: #ffffff;
  --text-color: #1f2937;
  --section-bg: #f9fafb;
  --card-bg: #ffffff;
  --border-color: #e5e7eb;
  --link-color: #3b82f6;
  --shadow: 0 4px 10px rgba(0, 0, 0, 0.08);
}

body.dark {
  --bg-color: #111827;
  --text-color: #f3f4f6;
  --section-bg: #1f2937;
  --card-bg: #1f2937;
  --border-color: #374151;
  --link-color: #60a5fa;
  --shadow: 0 4px 12px rgba(255, 255, 255, 0.05);
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}
