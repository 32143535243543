/* 📁 styles/Intro.css */

/* 自我介绍区域整体包裹 */
.intro-section {
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

/* 自我介绍标题 */
.intro-section h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

/* 自我介绍副标题 */
.intro-section h2 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 20px;
}

/* 标语文字 */
.intro-section .slogan {
  font-style: italic;
  font-size: 1rem;
  opacity: 0.9;
  margin-bottom: 24px;
}

/* 链接图标组 */
.intro-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
  margin-top: 20px;
}

/* 每个图标按钮 */
.intro-icons a {
  display: flex;
  align-items: center;
  gap: 8px;
  background: var(--btn-bg);
  color: var(--btn-color);
  padding: 10px 16px;
  border-radius: 8px;
  font-weight: 600;
  transition: background 0.3s ease;
}

/* hover 效果 */
.intro-icons a:hover {
  background: var(--btn-hover);
}

/* emoji 挥手 */
.wave-emoji {
  animation: wave 2s infinite;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(14deg); }
  30% { transform: rotate(-8deg); }
  45% { transform: rotate(14deg); }
  60% { transform: rotate(-4deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

/* 主容器 */
.home-container {
  display: flex;
  flex-direction: column; /* 默认竖直布局 */
  align-items: center;
  gap: 20px; /* 间距 */
}


/* 第一部分：头像 */
.profile-section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-image img {
  width: 200px;
  height: 200px;
  animation: bounce 2s infinite; /* 添加动画 */
}
/* 动画效果 */
@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/* 招小手 */
@keyframes waveHand {
  0% { transform: rotate(0deg); }
  15% { transform: rotate(20deg); }
  30% { transform: rotate(-15deg); }
  45% { transform: rotate(15deg); }
  60% { transform: rotate(-10deg); }
  75% { transform: rotate(10deg); }
  100% { transform: rotate(0deg); }
}

#wave-emoji.waving {
  display: inline-block;
  animation: waveHand 1s ease;
  transform-origin: bottom center;
}


/* 介绍文字 */
.introduction-section {
  text-align: center;
}
.introduction-section h1 {
  font-size: 2.5rem;
  font-weight: bold;
}
.introduction-section h2 {
  font-size: 1.5rem;
  color: #555;
}
body.dark-mode .introduction-section h2 {
  color: #e0dddd; /* 白色文字 */
}
.introduction-section p {
  font-size: 1rem;
  margin: 10px 0;
}


/* current interest的hover脑图 */
.node {
  cursor: pointer;
  position: relative;
  font-weight: bold;
  color: #333;
}

.popup-branch {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 8px 12px;
  border-radius: 10px;
  display: none;
  font-size: 0.9rem;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 999;
}

.node:hover + #branch-ai {
  display: block;
  top: 40px;
  left: 120px;
}

/* 第三部分：社交链接和简历按钮 */
.social-resume-section {
  display: flex;

  justify-content: center;
  gap: 15px;
}

.social-icon {
  height: 50px; /* 统一高度 */
  width: 50px; /* 图标的宽度与高度一致，按钮会自动适配文字 */
  font-size: 2rem;
  color: #000;
  text-decoration: none;
  display: inline-flex; /* 确保内容居中对齐 */
  align-items: center;
  justify-content: center;
}
/* 夜间模式社交图标样式 */
body.dark-mode .social-icon {
  color: #ffffff; /* 浅色图标 */
}

.social-icon:hover {
  transform: scale(1.6); /* 鼠标悬停时放大 */
  transform: rotate3d(1, 1, 1, 30deg); /* 3D旋转 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* Resume 按钮样式 */
.resume-button {
  text-decoration: none; /* 去掉下划线 */
  padding: 0 20px; /* 内边距适配文字 */
  background-color: #000; /* 按钮背景色 */
  color: #fff;
  font-size: 1rem; /* 按钮文字大小 */
  display: inline-flex;
  align-items: center;
  gap: 8px; /* 文字和图标的间距 */
  border-radius: 8px;
}
body.dark-mode .resume-button {
  background-color: #ffffff; /* 白色按钮 */
  color: #000000; /* 黑色文字 */
}
.resume-button span {
  margin-right: 8px; /* 文字与图标之间的距离 */
}

.resume-button i {
  font-size: 1.2rem; /* 图标大小 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* 悬停效果，只有图标旋转 */
.resume-button:hover i {
  font-size: 1.5rem;
  transform: rotate(45deg);
}

.resume-button:hover {
  background-color: #747474; /* 按钮背景变色 */
}

/* 针对手机（480px 以下） */
@media screen and (max-width: 480px) {

  .logo {
    width: 100px; /* 缩小 Logo */
    height: auto;
  }

  .resume-button {
    font-size: 0.9rem; /* 缩小 Resume 按钮文字 */
    padding: 10px 15px;
  }
  .contack-form button{
    width: 40%; /* 缩小按钮宽度 */
    font-size: 0.9rem; /* 缩小 Resume 按钮文字 */
  }
}