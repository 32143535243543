.external-links-section {
  text-align: center;
  margin-top: 4rem;
}

.external-links-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.external-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.external-link-item {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color);
  text-decoration: none;
  padding: 0.8rem 1.5rem;
  border: 2px solid var(--accent-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.external-link-item:hover {
  background-color: var(--accent-color);
  color: rgb(241, 174, 3);
  transform: scale(1.05);
}

body.dark-mode .external-link-item {
  color: var(--light-text-color); /* 自定义你的亮色变量，比如 #f0f0f0 */

}
body.dark-mode .external-link-item:hover {
  background-color: var(--light-accent-color); /* 自定义你的亮色变量，比如 #f0f0f0 */
  color: #e63403e3;
}