
/* ================== skills 部分样式 ==================== */
.skills {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 两列布局 */
  gap: 30px;
  max-width: 86%; /* 限制最大宽度 */
  margin:0 auto; /* 居中对齐 */
}

.card {
  background: rgba(255, 255, 255, 0.1); /* 半透明背景 */
  padding: 20px;
  margin: 30px;
  border-radius: 10px; /* 圆角 */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.2); /* 深化阴影 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡 */
}

/* 夜间模式技能卡片 */
body.dark-mode .card {
  background: rgba(255, 255, 255, 0.05); /* 更深的透明背景 */
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2); /* 浅色阴影 */
}


.card:hover {
  transform: scale(1.05) rotate(1deg); /* 放大并旋转 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* 阴影增加 */
}

.card h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
}
/* 环形图样式 */

.circle-chart {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-bottom: 40px; /* 整个图标区域与卡片底部的额外间距 */
}

.circle {
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: conic-gradient(
    #e0e0e0 0deg,
    #e0e0e0 360deg
  ); /* 初始全灰色 */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.5rem;
  text-align: center;
  color: #333; /* 黑色文字 */
}

body.dark-mode .circle {
  background: conic-gradient(
    #333 0deg,
    #333 360deg
  ); /* 夜间模式全黑色 */
  color: #ffffff; /* 白色文字 */
}


.circle:hover {
  transform: scale(1.2); /* 鼠标悬停时放大 */
  transition: transform 0.3s ease; /* 平滑过渡 */
}

/* 环形图中的图标 */
.skill-icon {
  font-size: 2rem;
  color: #333; /* 图标颜色为黑色 */
  position: absolute;
  top: 50%; /* 垂直居中 */
  left: 50%; /* 水平居中 */
  transform: translate(-50%, -50%); /* 将图标中心点移到父容器中心 */
  background-color: #ffffff; /* 图标背景颜色 */
  width: 50px; /* 图标容器的宽度 */
  height: 50px; /* 图标容器的高度 */
  border-radius: 50%; /* 图标容器为圆形 */
  display: flex;
  align-items: center;
  justify-content: center; /* 确保图标在容器内也居中 */
}

body.dark-mode .skill-icon {
  background-color: #333; /* 图标背景颜色 */
  color: #ffffff; /* 白色文字 */
}


/* 技能名字 */
.circle span {
  position: absolute;
  bottom: -22px; /* 放置在环形图下方 */
  font-size: 0.9rem;
  color: #555; /* 灰色文字 */
}

body.dark-mode .circle span {
  color: #e0e0e0; /* 白色文字 */
}



/* 实验 */


#skill-radar {
  background: transparent;
  backdrop-filter: blur(4px);
  position: relative;
}

#stars-bg {
  position: absolute;
  top: 0; left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
  pointer-events: none;
}
/* 雷达图容器 */
.radar-container {
  position: relative;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: radial-gradient(circle, 
    rgba(40,40,40,0.8) 0%,
    rgba(20,20,20,0.9) 100%
  );
  box-shadow: 0 0 30px rgba(255,100,100,0.2);
  margin: 0 auto;
}

.radar-ring {
  position: absolute;
  border: 1px solid rgba(255,100,100,0.3);
  border-radius: 50%;
  /* 精准居中定位 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 添加箭头装饰 */
.radar-line::after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: -3px;
  width: 7px;
  height: 7px;
  background: #ff6464;
  border-radius: 50%;
  animation: linePulse 1.5s infinite;
}

@keyframes linePulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.3); opacity: 0.4; }
}


.ring1 { 
  width: 60%; 
  height: 60%;
  top: 50%;
  left: 50%;
}

.ring2 { 
  width: 80%; 
  height: 80%;
  top: 50%;
  left: 50%;
}



/* 分类标签 */
.dimension-label {
  position: absolute;
  font-size: 1.1rem;
  color: #ff6464;
  text-shadow: 0 0 8px rgba(255,100,100,0.3);
  transform: translate(-50%, -50%);
  white-space: nowrap;
  padding: 8px 12px;
  background: transparent;
  border-radius: 20px;
  backdrop-filter: blur(4px);
  transition: all 0.3s ease;
}

.dimension-label:hover {
  transform: translate(-50%, -50%) scale(1.1);
  box-shadow: 0 0 15px rgba(255,100,100,0.4);
}
/* 技能节点 */
.radar-skill {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.radar-skill i {
  font-size: 1.5rem;
  margin-bottom: 5px;
  text-shadow: 0 0 8px rgba(255,100,100,0.3);
}

.radar-skill:hover {
  transform: scale(1.15);
  filter: brightness(1.2);
}


.radar-skill i {
  color: white;
  filter: drop-shadow(0 0 8px rgba(255, 100, 100, 0.6));
}

.radar-container {

  background: rgba(255, 255, 255, 0.03);
  z-index: 1;
}

.radar-ring {
  position: absolute;
  border: 1.5px dashed rgba(255, 196, 0, 0.994);
  border-radius: 50%;
  box-shadow: 0 0 8px rgb(255, 230, 0);
}
.radar-line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 1px;
  height: 300px;
  background: rgba(255, 0, 0, 0.2);
  transform-origin: bottom center;  /* 以底部为原点旋转 */
  z-index: 0;
}



body.dark-mode .radar-ring {
  border: 1.5px dashed rgba(255, 242, 0, 0.4);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
}


body.dark-mode .radar-line {
  background: rgba(255, 100, 100, 0.3);
}

@keyframes breath {
  0%, 100% {
    box-shadow: 0 0 8px rgba(255, 80, 80, 0.2);
  }
  50% {
    box-shadow: 0 0 16px rgba(255, 80, 80, 0.5);
  }
}
@keyframes core-glow {
  0%, 100% { box-shadow: 0 0 8px red; }
  50% { box-shadow: 0 0 18px red; }
}
.center-core {
  animation: core-glow 3s ease-in-out infinite;
}
.radar-skill:hover {
  transform: scale(1.2);
}
.radar-skill:hover i {
  transform: scale(1.4) rotate(8deg);
  background-color: rgba(255, 50, 50, 0.3);
  box-shadow: 0 0 20px rgba(255, 80, 80, 0.6);
}



/* 黑暗模式 label 更亮点 */
body.dark-mode .dimension-label {
  background: rgba(0, 0, 0, 0.6);
  color: #ff9999;
}


body.dark-mode .radar-skill span {
  color: #ffffff;
  text-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
  font-weight: 500;
}

body:not(.dark-mode) .radar-skill span {
  color: #222;
  text-shadow: none;
}

/* 夜间模式 */
body.dark-mode .skill-block {
  background-color: rgba(255, 255, 255, 0.05);
  color: #e0e0e0;
}

body.dark-mode .skill-block p {
  color: #ccc;
}


/* 修复圆形变形的CSS */
.radar-container {
  position: relative;
  width: 500px;  /* 固定宽高为1:1 */
  height: 500px; /* 必须与width相等 */
  margin: 20px auto;
  padding : 20px;
  overflow: visible; /* 确保溢出部分可见 */
}

@media (max-width: 768px) {
  .radar-container  {
    max-width: 300px;
    max-height: 300px;
  }
}

.radar-ring {
  position: absolute;
  border: 1px solid rgba(255,100,100,0.3);
  border-radius: 50%;
  /* 精准居中定位 */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 最外层圆形特别调整 */
.ring3 {
  width: 98%;   /* 略微缩小避免溢出 */
  height: 98%;  /* 必须与width相等 */
  box-shadow: 0 0 20px rgba(255,100,100,0.2); /* 添加发光效果 */
  border: 2px solid rgba(255, 204, 0, 0.95); /* 加粗边框 */
}
/* 分割线容器 */
.radar-line-container {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

/* 分割线样式 */
.radar-line {
  position: absolute;
  width: 1px;
  height: 50%; /* 精确等于半径长度 */
  bottom: 50%; /* 从圆心开始延伸 */
  left: 50%;
  background: linear-gradient(
    to bottom,
    rgba(255,100,100,0) 0%,
    rgba(255,100,100,0.4) 50%,
    rgba(255,100,100,0) 100%
  );
  transform-origin: bottom center; /* 以底部为旋转中心 */
  box-shadow: 0 0 8px rgba(255,100,100,0.2);
}



@keyframes linePulse {
  0%, 100% { transform: scale(1); opacity: 0.8; }
  50% { transform: scale(1.3); opacity: 0.4; }
}


.skills {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 1fr)); /* ✅ 自适应栅格 */
  justify-items: center; /* ✅ 内容居中 */
  width: 100%;
  max-width: 900px;
  margin: 0 auto; /* ✅ 水平居中 */
}