
/* ================ About ================ */

/* 引号 */
.quote-block {
  position: relative;
  padding: 40px 30px;
  font-style: italic;
  background: rgba(255, 255, 255, 0.4); 
  border-radius: 12px;
  backdrop-filter: blur(4px);
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.05);
  color: #444;
  z-index: 1;
  transition: all 0.3s ease;
}

.quote-block::before,
.quote-block::after {
  content: "“";
  font-size: 100px;
  font-family: Georgia, serif;
  color: rgb(255, 119, 0);
  position: absolute;
  z-index: 0;
}

.quote-block::before {
  top: -30px;
  left: -20px;
}

.quote-block::after {
  content: "”";
  bottom: -30px;
  right: -20px;
  transform: scaleX(1);
}

/* 🌙 黑夜模式适配 */
body.dark-mode .quote-block {
  background: rgba(255, 255, 255, 0.06);
  color: #f3f3f3;
  box-shadow: 0 0 24px rgba(255, 50, 50, 0.1);
}

body.dark-mode .quote-block::before,
body.dark-mode .quote-block::after {
  color: rgba(255, 100, 100, 0.3);
}


.bio-with-photo {
  display: flex;
  justify-content: space-between;
  align-items: center;      /* ✅ 改为垂直居中对齐 */
  gap: 48px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.bio-text {
  flex: 1;
  min-width: 300px;
}

.bio-photo img {
  max-width: 280px;        /* ✅ 限制最大宽度 */
  max-height: 220px;       /* ✅ 限制最大高度 */
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  object-fit: cover;
}
@media screen and (max-width: 768px) {
  .bio-with-photo {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }

  .bio-photo img {
    width: 90%;
    max-height: none;
  }
}
.bio-photo img:hover {
  transform: scale(1.03);
  transition: 0.3s ease;
}


/* Track 样式 */
.stage-track {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding-top: 30px;
  animation: fadein 1s ease-in-out;
}

.stage-box {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border-radius: 12px;
  font-family: "Fira Code", monospace;
  font-size: 1rem;
  opacity: 0;
  transform: scale(0.8);
  animation: rise 0.4s ease forwards;
}
body.dark-mode .stage-box {
  background-color: #fff;
  color: #000;
}
.stage-box:nth-child(1) { animation-delay: 0.2s; }
.stage-box:nth-child(3) { animation-delay: 0.6s; }
.stage-box:nth-child(5) { animation-delay: 1s; }

.stage-arrow {
  font-size: 1.5rem;
  color: #888;
  animation: blink 1.5s infinite ease-in-out;
}

.stage-box.final {
  background: linear-gradient(135deg, #cd3e3e, #dcb907);
  box-shadow: 0 0 12px rgba(255, 119, 0, 0.6);
  animation: blink 2.5s infinite ease-in-out;
}

@keyframes aiBreath {
  0%, 100% {
    box-shadow: 0 0 12px rgba(255, 100, 150, 0.6),
                0 0 24px rgba(255, 100, 150, 0.3);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 100, 150, 0.9),
                0 0 40px rgba(255, 0, 80, 0.5);
    transform: scale(1.08);
  }
  
}


/* 动画关键帧 */
@keyframes fadein {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}
@keyframes rise {
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes blink {
  0%, 100% { opacity: 0.4; transform: scale(1); }
  50% { opacity: 1; transform: scale(1.3); }
}


/* 教育经历 */
.edu-timeline {
  margin-top: 40px;
  padding-left: 60px;
  border-left: 3px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 18px;
}
.edu-item {
  position: relative;
  padding-left: 16px;
  animation: fadein 1s ease;
}
.edu-item::before {
  content: "🎓";
  position: absolute;
  left: -26px;
  top: 0;
}



.edu-wrapper {
  display: flex;
    flex-direction: column;
    align-items: center;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
}

.edu-wrapper .side {
  flex: 1;
  min-width: 300px;
}

.edu-cards {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 0px;
  margin: 0px;
  max-width:900px;
}
.edu-card {
 background: rgba(255, 255, 255, 0.1); /* 半透明背景 */
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 0.8rem;
  width: fit-content;
  min-width: 250px;
  box-shadow: none;
  transition: transform 0.2s ease;
  padding:20px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.2); /* 深化阴影 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 平滑过渡 */
}
.edu-card:hover {
  transform: scale(1.02);
  border-color: #aaa;
}


/* 夜间模式技能卡片 */
body.dark-mode .edu-card {
  background: rgba(255, 255, 255, 0.05); /* 更深的透明背景 */
  color: #ffffff; /* 白色文字 */
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2); /* 浅色阴影 */
}


.edu-card:hover {
  transform: scale(1.05) rotate(1deg); /* 放大并旋转 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3); /* 阴影增加 */
}

@media screen and (max-width: 768px) {
  .edu-wrapper {
    flex-direction: column;
    align-items: center;
  }

  .edu-cards {
    flex-direction: column;
  }
}
.github-heatmap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

}
.main-wrapper {
  max-width: 1100px;
  margin: 0 auto;     /* 💡 自动居中对齐 */
  padding: 0 20px;    /* 💡 两边内边距，防止靠边贴太死 */
}
@media screen and (max-width: 768px) {
  .main-wrapper {
    padding: 0 12px;
  }
}

.edu-cards {
  width: 100%;         /* ✅ 宽度撑满 */
  max-width: 900px;
  margin: 0 auto;       /* ✅ 居中 */
  flex-wrap: wrap;      /* ✅ 允许换行 */
  justify-content: center; /* ✅ 居中对齐内容 */
}