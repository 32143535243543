
/* ================ projects 部分样式 ============ */
  /* 更现代化的 details 分类卡片样式 */
  .project-category {
    background: linear-gradient(145deg, #fafafa, #f0f0f0);
    border-radius: 16px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.08);
    margin: 1.5rem 0;
    padding: 0.5rem 1.2rem;
    transition: all 0.3s ease-in-out;
    border: 1px solid #ddd;
  }

  .project-category summary {
    cursor: pointer;
    font-size: 1.25rem;
    font-weight: 600;
    padding: 0.6rem 0;
    color: #222;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    list-style: none;
  }

  .project-category[open] {
    background: linear-gradient(145deg, #ffffff, #f2f2f2);
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(0,0,0,0.1);
  }

  .project-category[open] summary {
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
  }

  .project-category summary::-webkit-details-marker {
    display: none;
  }

  .project-category summary::before {
    content: "▶";
    display: inline-block;
    transform: rotate(0deg);
    transition: transform 0.3s ease;
    color: #888;
  }

  .project-category[open] summary::before {
    transform: rotate(90deg);
    content: "▼";
  }


  .badge {
    background: #eee;
    color: #555;
    padding: 0.15rem 0.4rem;
    font-size: 0.8rem;
    border-radius: 8px;
    margin-left: 0.5rem;
  }

  .project-buttons {
    display: flex;
    gap: 1rem;
    margin-top: 0.6rem;
  }

  .project-buttons .btn {
    background: #000;
    color: #fff;
    border-radius: 50%;
    padding: 0.6rem;
    width: 2.6rem;
    height: 2.6rem;
    text-align: center;
    font-size: 1.2rem;
    transition: transform 0.2s ease;
  }

  .project-buttons .btn:hover {
    transform: translateY(-4px) scale(1.1);
    background: #444;
  }




/* 分类项目展示 */
.projects-section {
  padding: 4rem 1rem;
  max-width: 1200px;
  margin: 0 auto;
}

.section-title {
  text-align: center;
  margin-bottom: 3rem;
  color: var(--text-primary);
}

.projects-container {
  display: grid;
  grid-template-columns: 240px 1fr;
  width:900px;
}

.category-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: sticky;
  top: 120px;
  height: fit-content;
  width:250px;

}

.nav-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: var(--bg-secondary);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 250px;
  
}

.nav-item:hover {
  background: var(--bg-hover);
  transform: translateX(5px);
}

.nav-item.active {
  background: var(--bg-active);
  font-weight: 600;
}

.category-icon {
  font-size: 1.4rem;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1.5rem;
  padding: 0.5rem;
}

.project-card {
  background: var(--bg-secondary);
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  width:500px;
}

.card-header {
  margin-bottom: 1rem;
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.75rem;
}

.tag {
  background: var(--tag-bg);
  color: var(--tag-text);
  padding: 0.25rem 0.75rem;
  border-radius: 20px;
  font-size: 0.85rem;
}

.project-image {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-image:hover img {
  transform: scale(1.03);
}

.action-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  
}

.link-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;
  color: white;
  text-decoration: none;
  transition: opacity 0.3s ease;
}

.link-button:hover {
  opacity: 0.9;
}








.swiper-pagination {
  position: relative !important;
  margin-top: 1rem;
}

.swiper-pagination-bullet {
  transition: transform 0.2s ease;
}

.swiper-pagination-bullet:hover {
  transform: scale(1.2);
}

.status-badge {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 999px;
  vertical-align: middle;
}



.custom-pagination {
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background: #e5e7eb;
  opacity: 0.6;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.swiper-pagination-bullet-active {
  width: 24px;
  background: currentColor !important;
  opacity: 1 !important;
}

.action-buttons {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 1rem;
  margin-top: 1.5rem;
}

.project-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 12px 16px;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.project-link:hover {
  background: var(--hover-bg) !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.status-badge {
  font-size: 0.8rem;
  padding: 0.25rem 0.75rem;
  margin-left: 1rem;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 999px;
  vertical-align: middle;
}
/* 在CSS中添加 */
.project-carousel {
  margin: 1rem 0;
  border-radius: 8px;
  overflow: hidden;
}


 



.custom-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}









.custom-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}



/* 分页器定位修正 */
.custom-pagination {
  bottom: -25px !important; /* 调整分页器位置 */
  position: absolute;
  z-index: 10;
}




.custom-pagination {
  bottom: 20px !important; /* 调整分页器位置 */
  transform: translateX(-50%) !important;
  z-index: 10;
}





/* 分页器精确定位 */
.custom-pagination {
  bottom: 20px !important;
  transform: translateX(-50%);
  gap: 8px;
}



/* 💡 让 Swiper 分页器的小圆点更明显 */
.swiper-pagination-bullet {
  background: #888 !important; /* 默认颜色加深一点 */
  opacity: 0.9 !important;     /* 增加不透明度 */
  width: 10px;
  height: 10px;

  transition: all 0.3s ease;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); /* 增加轻微阴影增强对比 */
}

.swiper-pagination-bullet-active {
  background: #111 !important; /* 激活状态为黑色（清晰） */
  width: 14px;
  height: 14px;
  transform: scale(1.3);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}

/* 🌙 夜间模式优化：背景色和 bullet 色反转 */
body.dark-mode .swiper-pagination-bullet {
  background: #ccc !important;
  opacity: 0.9 !important;
}

body.dark-mode .swiper-pagination-bullet-active {
  background: #fff !important;
}


.custom-pagination {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 8px;
  z-index: 10;
}






/* 分页器定位修正 */
.custom-pagination {
  bottom: -25px !important; /* 调整分页器位置 */
  position: absolute;
  z-index: 10;
}




.custom-pagination {
  bottom: 20px !important; /* 调整分页器位置 */
  transform: translateX(-50%) !important;
  z-index: 10;
}















.project-card {
  background: var(--card-bg);
  border-radius: 12px;
  padding: 1.5rem;
  margin: 10px 50px; /* 调整卡片间距aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa */
  max-width: 700px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease;
}

.swiper-media-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 8px;
}

.swiper-media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-button-next,
.swiper-button-prev {
  color: var(--accent-color);
}

.swiper-pagination-fraction {
  font-weight: bold;
  margin-top: 0.5rem;
  color: var(--text-color);
  text-align: center;
}

.project-carousel-wrapper {
  position: relative;
  max-width: 880px;
  margin: 0 auto;
}

.project-nav-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  
  margin-right: 250px;
}

.project-nav {
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-primary, #333);
  transition: transform 0.2s ease;
}

.project-nav:hover {
  transform: scale(1.4);
}

.project-pagination {
  font-size: 1rem;
  color: var(--text-secondary, #666);
  font-weight: 500;
}

















/* 🌙 夜间模式下的项目卡片优化 */
body.dark-mode .project-card {
  background-color: #1e1e1e; /* 深灰背景 */
  color: #f5f5f5;            /* 明亮文字 */
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.05); /* 柔和浅亮阴影 */
  border: 1px solid rgba(255, 255, 255, 0.08);       /* 柔和边框 */
}

body.dark-mode .project-card h3,
body.dark-mode .project-card p {
  color: #eee;
}

body.dark-mode .tag {
  background-color: rgba(255, 255, 255, 0.08);
  color: #ddd;
}

body.dark-mode .project-link {
  border: 1px solid rgba(255, 255, 255, 0.15);
}

body.dark-mode .project-link:hover {
  background: rgba(255, 255, 255, 0.1);
}













@media (max-width: 768px) {
  .projects-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.5rem;
  }

  .category-nav {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: static;
    width: 100%;
    gap: 0.5rem;
    margin-bottom: 1rem;
  }

  .nav-item {
    width: auto;
    padding: 0.75rem 1.2rem;
    justify-content: center;
  }

  .project-nav-buttons {
    margin-right: 0;
    justify-content: center;
  }

  .project-card {
    margin: 0 auto; /* 居中显示卡片 */
    margin-left: 50px;
    margin-right: 50px;

  
  }
}
@media (max-width: 768px) {
  .project-card {
    max-width: 100%;         /* 💡 关键：不让它死死定在 700px */
    width: 90%;              /* 🪄 让它自然缩进一些，不顶边 */
    margin: 0 auto;          /* ✨ 保证它始终居中 */
    padding: 1rem;           /* 🧸 小屏加内边距避免内容贴边 */
  }

  .project-carousel-wrapper {
    max-width: 100%;
    width: 100%;
  }

  .projects-container {
    padding: 0 1rem;         /* 🫶 整个项目区域缩进些避免超边 */
  }
}

@media (max-width: 480px) {
  .project-card {
    padding: 0.75rem;        /* ✨ 极小屏再稍微紧凑一点 */
  }

  .action-buttons {
    grid-template-columns: 1fr; /* 💡 小屏按钮堆叠显示避免过挤 */
  }

  .category-nav{
    margin:0 0 0 0;  
    padding:0;
  }

  
}
@media (max-width: 768px) {
  .category-nav {
    background-color: var(--bg-secondary); /* 💡 添加背景色撑满 */
    border-radius: 12px;                   /* 🌈 加点圆角更优雅 */
    padding: 0.5rem 1rem;                  /* 🎀 左右内边距，防止文字贴边 */
    margin: 0 auto;                        /* 💫 居中容器本身 */
    max-width: 100vw;                      /* 🛡️ 保证绝对撑满屏幕宽度 */
    box-sizing: border-box;                /* ✨ 避免 padding 撑爆宽度 */
  }
}
