/* ------------------------- */
/* 🎯 Base.css：全局重置、字体、变量 */
/* ------------------------- */

/* Reset 样式清除 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
  line-height: 1.6;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* 链接默认样式 */
a {
  text-decoration: none;
  color: inherit;
}

/* 图片自适应 */
img {
  max-width: 100%;
  display: block;
}

/* 全局变量（初始为 light theme） */
:root {
  --background-color: #f9f9f9;
  --text-color: #1f2937;
  --primary-color: #6366f1;
  --secondary-color: #e0e7ff;
  --border-color: #e5e7eb;
  --card-radius: 0.75rem;
  --transition: 0.3s ease-in-out;
}

/* 黑暗模式变量 */
body.dark {
  --background-color: #111827;
  --text-color: #f3f4f6;
  --primary-color: #818cf8;
  --secondary-color: #1f2937;
  --border-color: #374151;
}

body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'Poppins', sans-serif;
  margin: 50px;
  padding: 100px;
  background-color: rgb(244, 244, 244);
  color: #000000; /* 全局字体颜色为白色 */
  text-align: center; /* 文本居中对齐 */
  line-height: 1.6; /* 行高 */
}
body.dark-mode {
  background-color: rgb(18,18,18); /* 黑色背景 */
  color: #fff; /* 白色字体 */
}

h2 {
  font-size: 30px;
}
h1, h2 {
  margin: 20px 0; /* 标题间距 */
}
body.light {
  --bg-color: white;
  --text-color: #111;
}

body.dark {
  --bg-color: #111;
  --text-color: white;
}

body {
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background 0.3s ease, color 0.3s ease;
}

/* 根布局修正，保证 app 居中、响应式 */
body,
#root {
  width: 100%;
  display: flex;
  justify-content: center;
}


/* ============================================= */
/* =============== sections 部分样式 ============ */
/* ============================================= */

.section {
  padding: 40px 0; /* 内边距 */
  opacity: 0; /* 默认透明 */
  transform: translateY(30px); /* 下移 */
  transition: opacity 0.6s ease, transform 0.6s ease; /* 动画过渡 */
}

.section.visible {
  opacity: 1; /* 进入视口时显示 */
  transform: translateY(0); /* 移回原位 */
}

/* ====== 响应式 ====== */
/* 针对平板（768px 以下） */
@media screen and (max-width: 768px) {

  .container {
    width: 85%; /* 更窄的内容宽度 */
    padding: 10px; /* 减小内边距 */
  }
}

